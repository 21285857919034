import type { BigNumberish } from '@ethersproject/bignumber'

const ROOT_URL_PRODUCTION = 'https://gobblers.xyz'
const TWITTER_HANDLE = '@artgobblers'

export interface GameAddresses {
  ART_GOBBLERS: string
  GOO: string
  PAGES: string
}

const MEV_ROUND_ONE_DEPLOYMENT_ADDRESSES_RINKEBY: GameAddresses = {
  ART_GOBBLERS: '0x383049bb3ab2300580f2e51887d18efd8a2a00d3'.toLowerCase(),
  GOO: '0xba3fe78a799f8b6203face16179d98724f9a62c5'.toLowerCase(),
  PAGES: '0xe82b082ba29e7a75298dccfb7c1431c980e9f0c7'.toLowerCase(),
  // 0xfaf7affa9a3ade959942a949eecf9ab9a7826d34 -- Community Reserve
  // 0xb4299fa454fba77cc54e0d2d49c3dfc160ee5910 -- Team Reserve
}

const getAddressesForGobblerInstance = (chainId: BigNumberish): GameAddresses => {
  return MEV_ROUND_ONE_DEPLOYMENT_ADDRESSES_RINKEBY
}

const subgraphRinkberyApiRootUrl = 'https://api.studio.thegraph.com/query/30338/bbtest/v0.0.10'

const getSubgraphAddress = (chainId: BigNumberish) => {
  return subgraphRinkberyApiRootUrl
}

const DEFAULT_CHAIN_ID = '4' // Rinkeby
const DEFAULT_CHAIN_ID_NUMBER = 4

const TEST_USER_WALLET_ADDRESS = '0x1D18077167c1177253555e45B4b5448B11E30b4b'

const SIWE_CONFIG = {
  statement: 'Welcome to Art Gobblers. Please sign in.\nGobble gobble.',
  version: '1',
}

export {
  ROOT_URL_PRODUCTION,
  TWITTER_HANDLE,
  MEV_ROUND_ONE_DEPLOYMENT_ADDRESSES_RINKEBY as ADDRESSES_RINKEBY,
  getAddressesForGobblerInstance,
  getSubgraphAddress,
  DEFAULT_CHAIN_ID,
  DEFAULT_CHAIN_ID_NUMBER,
  TEST_USER_WALLET_ADDRESS,
  SIWE_CONFIG,
}
