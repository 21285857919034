import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Item } from '@react-stately/collections'
import { OverlayContainer } from '@react-aria/overlays'
import { useTransition, animated } from '@react-spring/web'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useDisconnect, useAccount } from 'wagmi'

import { ANoUnderline } from './Links'
import { useAppStore } from '../../stores/app'
import { UserAvatarMenuButon } from './HeaderMenu'
import { PDesktop } from './Typography'
import { PrimaryButton } from './Buttons'
import { getShortenedAddress } from '../../web3/chains'
import { EyeballNoShadow } from './icons/Eyeball'

const HeaderOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  margin-left: 32px;
  margin-right: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 24px;
    margin-right: 24px;
  `}
  margin-top: 20px;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const HeaderMark = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.04em;

  /* Increase hitbox */
  padding: 6px;
  margin-left: -6px;
`

const Circle = styled.div`
  min-height: 44px;
  min-width: 44px;
  max-width: 44px;
  max-height: 44px;
  height: 44px;
  width: 44px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlackCircle = styled(Circle)`
  background: #171717;
  padding: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(23, 23, 23, 0.12);
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`

const GrayInsetCircle = styled(Circle)`
  box-shadow: inset 0px 1px 4px rgba(23, 38, 47, 0.12);
  filter: drop-shadow(0px -4px 4px rgba(255, 255, 255, 0.32));
  background: #bdc8cf;
  user-select: none;
  cursor: pointer;
`

const ProfileImage = styled.img`
  object-fit: contain;
  border-radius: 100%;
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const HeaderSpacer = styled.div`
  height: 136px;
  min-height: 136px;
  max-height: 136px;
`

const AnimatedOverlayContainer = animated(OverlayContainer)

const Header = () => {
  const showLoginDialog = useAppStore((s) => s.isLoginModalOpen)
  const setShowLoginDialog = useAppStore((s) => s.setIsLoginModalOpen)

  const isUserConnecting = useAppStore((s) => s.hasActiveLoginAttempt)
  const setIsUserConnecting = useAppStore((s) => s.setHasActiveLoginAttempt)

  const { disconnect } = useDisconnect()
  const accountData = useAccount()

  const transitions = useTransition(showLoginDialog, {
    from: {
      opacity: 0.0001,
      y: -10,
      // zIndex: 101,
    },
    enter: {
      opacity: 1,
      y: 0,
      // zIndex: 101,
    },
    leave: {
      opacity: 0.0001,
      y: 10,
      // zIndex: 101,
    },
    config: {
      // duration: 30000,
    },
  })

  // While a user is connecting by clicking on 3rd party wallet modals, let's not close the login dialog
  const hideLoginModalOnClickAway = isUserConnecting !== null ? false : true

  const router = useRouter()
  const { isReady, exportMode } = router.query
  if (exportMode) {
    return null
  }

  return (
    <>
      <HeaderOuter>
        <HoverHeaderContainer>
          <HoverHeaderLeftContainer>
            <Link
              passHref
              href={{
                pathname: '/',
              }}
            >
              <StyledANoUnderline>
                <div
                  style={{
                    borderRadius: '100%',
                    boxShadow: `-1px -3px 4px rgba(255, 255, 255, 0.2), 0px 16px 32px rgba(27, 26, 37, 0.24), 0px 12px 24px rgba(27, 26, 37, 0.16), 0px 4px 8px rgba(27, 26, 37, 0.12)`,
                  }}
                >
                  <EyeballNoShadow />
                </div>
                {/* <HeaderMark style={{ color: '#ffffff' }}>Art Gobblers</HeaderMark> */}
              </StyledANoUnderline>
            </Link>
            {/* <Link
              passHref
              href={{
                pathname: '/gobbler/[gobblerId]',
                query: {
                  gobblerId: '1234',
                },
              }}
            >
              <StyledANoUnderline>
                <HeaderMark style={{ color: '#ffffff' }}>Gallery</HeaderMark>
              </StyledANoUnderline>
            </Link>
            <Link
              passHref
              href={{
                pathname: '/gobbler/mint',
              }}
            >
              <StyledANoUnderline>
                <HeaderMark style={{ color: '#ffffff' }}>Factory</HeaderMark>
              </StyledANoUnderline>
            </Link>
            <Link
              passHref
              href={{
                pathname: '/page/mint',
              }}
            >
              <StyledANoUnderline>
                <HeaderMark style={{ color: '#ffffff' }}>Lab</HeaderMark>
              </StyledANoUnderline>
            </Link>
            <Link
              passHref
              href={{
                pathname: '/legendaries',
              }}
            >
              <StyledANoUnderline>
                <HeaderMark style={{ color: '#ffffff' }}>Legendaries</HeaderMark>
              </StyledANoUnderline>
            </Link> */}
          </HoverHeaderLeftContainer>

          <HoverHeaderRightContainer>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted,
              }) => {
                return (
                  // <div
                  //   style={{
                  //     minHeight: 56,
                  //   }}
                  //   {...(!mounted && {
                  //     'aria-hidden': true,
                  //     'style': {
                  //       opacity: 0,
                  //       pointerEvents: 'none',
                  //       userSelect: 'none',
                  //     },
                  //   })}
                  // >
                  <>
                    {(() => {
                      if (!mounted || !account || !chain) {
                        return (
                          <PrimaryButton
                            onPress={openConnectModal}
                            style={{
                              borderRadius: 30,
                              width: 'fit-content',
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 'auto',
                              boxShadow: 'none',
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                            buttonSize="small"
                            type="button"
                          >
                            Connect
                          </PrimaryButton>
                        )
                      }

                      return (
                        <>
                          {/* <Link
                            href={{
                              pathname: '/draw',
                            }}
                            passHref
                          >
                            <PrimaryButton
                              as={'a'}
                              style={{
                                borderRadius: 30,
                                width: 'fit-content',
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 10,
                                paddingBottom: 10,
                                height: 'auto',
                                boxShadow: 'none',
                                marginTop: 10,
                                marginBottom: 10,
                                marginRight: 8,
                              }}
                              // onPress={openConnectModal}
                              buttonSize="small"
                              type="button"
                            >
                              Draw
                            </PrimaryButton>
                          </Link> */}

                          <UserAvatarMenuButon
                            label="Menu Actions"
                            onAction={(key) => {
                              switch (key) {
                                case 'profile':
                                  router.push({
                                    pathname: '/user/[userId]',
                                    query: {
                                      userId: account.address,
                                    },
                                  })
                                case 'disconnect':
                                  disconnect()
                                  break
                                default:
                                  break
                              }
                            }}
                          >
                            <Item textValue="Profile" key="profile">
                              <PDesktop style={{ fontWeight: '500' }}>
                                Profile ({account.ensName ?? getShortenedAddress(account.address)})
                              </PDesktop>
                            </Item>
                            <Item textValue="Copy wallet address" key="copy">
                              <PDesktop style={{ fontWeight: '500' }}>Copy Address</PDesktop>
                            </Item>
                            <Item textValue="Disconnect wallet" key="disconnect">
                              <div style={{}}>
                                <PDesktop style={{ fontWeight: '500' }}>Log out</PDesktop>
                              </div>
                            </Item>
                          </UserAvatarMenuButon>
                        </>
                      )
                    })()}
                  </>

                  // </div>
                )
              }}
            </ConnectButton.Custom>
          </HoverHeaderRightContainer>
        </HoverHeaderContainer>
      </HeaderOuter>
    </>
  )
}

const StyledANoUnderline = styled(ANoUnderline)`
  padding-left: 20px;
  padding-right: 20px;
`

const HoverHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* overflow: hidden; */

  padding: 0 16px;
  max-width: 1312px;
  height: 72px;
  width: 100%;

  background: linear-gradient(180deg, rgba(125, 175, 145, 0.16) 0%, rgba(156, 157, 168, 0) 100%);
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.12), inset 0px 2px 4px rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 60px;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 60px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const HoverHeaderLeftContainer = styled.div`
  display: flex;
  gap: 8px;
  /* overflow-x: hidden; */
  align-items: center;
`

const HoverHeaderRightContainer = styled.div`
  display: flex;

  align-items: center;
  flex-direction: row;
`

export { Header, HeaderSpacer }

{
  /* <Link passHref href={'/'}>
<ANoUnderline>
  <HeaderMark style={{ color: '#ffffff' }}>Art Gobblers</HeaderMark>
</ANoUnderline>
</Link>
<HeaderRight>
<ConnectButton.Custom>
  {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
    return (
      <div
        style={{
          minHeight: 56,
        }}
        {...(!mounted && {
          'aria-hidden': true,
          'style': {
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none',
          },
        })}
      >
        <PrimaryButton
          style={{
            borderRadius: 30,
            width: 'fit-content',
            paddingLeft: 16,
            paddingRight: 16,
            boxShadow: 'none',
          }}
          onPress={openConnectModal}
          buttonSize="small"
          type="button"
        >
          Draw
        </PrimaryButton>

        {(() => {
          if (!mounted || !account || !chain) {
            return (
              <PrimaryButton onPress={openConnectModal} buttonSize="small" type="button">
                Connect Wallet
              </PrimaryButton>
            )
          }

          return (
            <UserAvatarMenuButon
              label="Menu Actions"
              onAction={(key) => {
                switch (key) {
                  case 'disconnect':
                    disconnect()
                    break
                  default:
                    break
                }
              }}
            >
              <Item textValue="Profile" key="profile">
                <PDesktop style={{ fontWeight: '500' }}>
                  Profile ({account.address})
                </PDesktop>
              </Item>
              <Item textValue="Sell NFTs" key="sell">
                <PDesktop style={{ fontWeight: '500' }}>List items for sale</PDesktop>
              </Item>
              <Item textValue="Copy wallet address" key="copy">
                <PDesktop style={{ fontWeight: '500' }}>Copy Address</PDesktop>
              </Item>
              <Item textValue="Disconnect wallet" key="disconnect">
                <div style={{}}>
                  <PDesktop style={{ fontWeight: '500' }}>Log out</PDesktop>
                </div>
              </Item>
            </UserAvatarMenuButon>
          )
        })()}
      </div>
    )
  }}
</ConnectButton.Custom>
</HeaderRight> */
}
